import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row, Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
moment.locale('fr');
import Loading from '../../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Overview from './Import/overview';
import ImportedShopsList from './Import/list';
import {
  knownCaveFields,
  organizedCaveFields,
  keywordsCaveFields,
} from './Import/dicts';
import ImportedShopsMatTable from '../components/forImportedShops/Table/MatTable';
import AddedShopsMatTable from '../components/forAddedShops/Table/MatTable';

class Imports extends React.Component {
  state = {
    loaded: false,
    loading: false,
    subscription: null,
    imports: [],
    currentImport: null,
    formShown: false,
    formData: { custom_attributes: {} },
    pendingCustomAttributes: [],
    pendingCustomAttributesRenames: [],
    pendingCustomAttributesRemoves: [],
    newFormData: {},
    pendingFile: null,
    pendingFileRemove: false,
    search: '',
    showDetail: false,
    currentFormGroup: 'native',
    showCustomAttributesManager: false,
    editableCustomFields: [],
  };

  _showNotification(w) {
    Swal.fire({
      icon: 'success',
      title: `Information`,
      html: w,
    });
  }

  _showError(w) {
    Swal.fire({
      icon: 'error',
      title: `Erreur`,
      html: w,
    });
  }

  constructor(props) {
    super(props);

    this.openImport = this.openImport.bind(this);
    this.shopOpen = this.shopOpen.bind(this);
    this.shopCreate = this.shopCreate.bind(this);
    this.shopUpdate = this.shopUpdate.bind(this);
    this.shopDelete = this.shopDelete.bind(this);
    this.hideUpdateForm = this.hideUpdateForm.bind(this);
    this.showCreateForm = this.showCreateForm.bind(this);
    this.showUpdateForm = this.showUpdateForm.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.deleteImport = this.deleteImport.bind(this);
    this.handleFilePick = this.handleFilePick.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
    this.showFormGroup = this.showFormGroup.bind(this);
    this.refreshCurrentImport = this.refreshCurrentImport.bind(this);
    this.appendNewCustomField = this.appendNewCustomField.bind(this);
    this.removeNewCustomField = this.removeNewCustomField.bind(this);
    this.removeCustomField = this.removeCustomField.bind(this);
    this.handlePendingCustomAttributeLabelChange = this.handlePendingCustomAttributeLabelChange.bind(
      this
    );
    this.handleCustomAttributeRename = this.handleCustomAttributeRename.bind(
      this
    );
    this.openCustomAttributesManager = this.openCustomAttributesManager.bind(
      this
    );
    this.hideCustomAttributesManager = this.hideCustomAttributesManager.bind(
      this
    );
    this.enableCustomFieldRename = this.enableCustomFieldRename.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/subscription/get`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
      })
      .then((res) => {
        this.setState({
          subscription: res.data.content.subscription,
        });

        axios
          .post(
            `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/imports/list`,
            {
              id: user.id,
              token: user.token,
              passphrase: user.passphrase,
            }
          )
          .then((res) => {
            if (!this.props.match.params.id)
              this.setState({
                imports: res.data.content.imports,
                loaded: true,
                loading: false,
              });
            else {
              axios
                .post(
                  `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/imports/get`,
                  {
                    id: user.id,
                    token: user.token,
                    passphrase: user.passphrase,
                    importId: this.props.match.params.id,
                  }
                )
                .then((resx) => {
                  this.setState({
                    loaded: true,
                    loading: false,
                    imports: res.data.content.imports,
                    currentImport: resx.data.content.import,
                  });
                });
            }
          });
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      this.props.match.params.id
    ) {
      const {
        authentication: { user },
      } = this.props;

      if (this.state.imports.some((i) => i.pk == this.props.match.params.id))
        axios
          .post(
            `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/imports/get`,
            {
              id: user.id,
              token: user.token,
              passphrase: user.passphrase,
              importId: this.props.match.params.id,
            }
          )
          .then((resx) => {
            this.setState({
              loaded: true,
              loading: false,
              currentImport: resx.data.content.import,
            });
          });
    }
  }

  refreshCurrentImport() {
    const {
      authentication: { user },
    } = this.props;

    axios
      .post(
        `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/imports/get`,
        {
          id: user.id,
          token: user.token,
          passphrase: user.passphrase,
          importId: this.props.match.params.id,
        }
      )
      .then((resx) => {
        this.setState({
          loaded: true,
          loading: false,
          currentImport: resx.data.content.import,
        });
      });
  }

  openImport(i) {
    this.props.history.push(`/finder/intelligence/imports/${i.pk}`);
  }

  shopOpen(id) {
    window.open(
      `${process.env.REACT_APP_BOTTL_WEBROOT}/caves/commerces/IMP-${id}-.html?is_imported=1`,
      '_blank'
    );
  }

  shopDelete(id) {
    Swal.fire({
      title: 'Attention',
      text: `La suppression d'une cave importée est irréversible, voulez-vous continuer ?`,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: `Annuler`,
    }).then((result) => {
      if (result.isDenied) return;
      if (!result.isConfirmed) return;

      this.setState({ loading: true, loaded: false });

      const {
        authentication: { user },
      } = this.props;
      let endpoint = `finder/imported-shops/remove`;

      axios
        .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
          id: user.id,
          token: user.token,
          passphrase: user.passphrase,
          shopId: id,
        })
        .then((res) => {
          this.setState({
            loaded: true,
            loading: false,
            currentImport: {
              ...this.state.currentImport,
              shops: [
                ...this.state.currentImport.shops.filter((s) => s.id != id),
              ],
            },
          });

          this._showNotification('Votre cave importée a bien été supprimée.');
          setTimeout(() => {
            document
              .querySelector('.as-import-shops-overview')
              .scrollIntoView();
          }, 250);
        })
        .catch((err) => {
          this.setState({
            loaded: true,
            loading: false,
          });
        });
    });
  }

  shopCreate(id) {
    if (
      Object.entries(this.state.newFormData).length === 0 &&
      Object.entries(this.state.pendingCustomAttributes).length === 0 &&
      Object.entries(this.state.pendingCustomAttributesRenames).length === 0 &&
      Object.entries(this.state.pendingCustomAttributesRemoves).length === 0
    )
      return this.hideUpdateForm();

    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/imported-shops/create`;

    const _showSuccess = () => {
      if (
        this.state.pendingCustomAttributes.length > 0 ||
        this.state.pendingCustomAttributesRenames.length > 0 ||
        this.state.pendingCustomAttributesRemoves.length > 0
      )
        this.refreshCurrentImport();

      this.setState({
        loaded: true,
        loading: false,
        formShown: false,
        formData: { custom_attributes: {} },
        newFormData: {},
        pendingCustomAttributes: [],
        pendingCustomAttributesRenames: [],
        pendingCustomAttributesRemoves: [],
        editableCustomFields: [],
        pendingFile: null,
        pendingFileRemove: false,
      });
      this._showNotification('Votre cave a bien été ajoutée à votre import.');
      setTimeout(() => {
        document.querySelector('.as-import-shops-overview').scrollIntoView();
      }, 250);
    };

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
        data: this.state.newFormData,
        newCustomAttributes: this.state.pendingCustomAttributes,
        newCustomAttributesRenames: this.state.pendingCustomAttributesRenames,
        newCustomAttributesRemoves: this.state.pendingCustomAttributesRemoves,
        importId: this.state.currentImport.pk,
      })
      .then((res) => {
        this.setState(
          {
            currentImport: {
              ...this.state.currentImport,
              shops: [...this.state.currentImport.shops, res.data.content.shop],
            },
          },
          () => {
            // Upload pending file if any
            if (this.state.pendingFile) {
              let fd = new FormData();
              fd.append('file', this.state.pendingFile);
              fd.append('id', user.id);
              fd.append('shopId', res.data.content.shop.id);
              fd.append('token', user.token);
              fd.append('passphrase', user.passphrase);
              axios
                .post(
                  `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/imported-shops/update-file`,
                  fd,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  }
                )
                .then((r) => {
                  this.setState({
                    currentImport: {
                      ...this.state.currentImport,
                      shops: this.state.currentImport.shops.map((s) =>
                        s.id != res.data.content.shop.id
                          ? s
                          : {
                              ...s,
                              image_src: `${process.env.REACT_APP_BOTTL_WEBROOT}/photos/finder/${s.id}-${this.state.pendingFile.name}`,
                            }
                      ),
                    },
                  });
                  _showSuccess();
                });
            } else _showSuccess();
          }
        );
      });
  }

  shopUpdate(id) {
    if (
      Object.entries(this.state.newFormData).length === 0 &&
      Object.entries(this.state.pendingCustomAttributes).length === 0 &&
      Object.entries(this.state.pendingCustomAttributesRenames).length === 0 &&
      Object.entries(this.state.pendingCustomAttributesRemoves).length === 0
    )
      return this.hideUpdateForm();

    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/imported-shops/update`;

    const _performUpdate = () => {
      if (
        this.state.pendingCustomAttributes.length > 0 ||
        this.state.pendingCustomAttributesRenames.length > 0 ||
        this.state.pendingCustomAttributesRemoves.length > 0
      )
        this.refreshCurrentImport();

      axios
        .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
          id: user.id,
          token: user.token,
          passphrase: user.passphrase,
          shopId: id,
          importId: this.state.currentImport.pk,
          data: this.state.newFormData,
          newCustomAttributes: this.state.pendingCustomAttributes,
          newCustomAttributesRenames: this.state.pendingCustomAttributesRenames,
          newCustomAttributesRemoves: this.state.pendingCustomAttributesRemoves,
        })
        .then((res) => {
          this.setState({
            loaded: true,
            loading: false,
            formShown: false,
            pendingFile: null,
            pendingFileRemove: false,
            formData: { custom_attributes: {} },
            newFormData: {},
            pendingCustomAttributes: [],
            pendingCustomAttributesRenames: [],
            pendingCustomAttributesRemoves: [],
            editableCustomFields: [],
            showCustomAttributesManager: false,
            currentImport: {
              ...this.state.currentImport,
              shops: [
                ...this.state.currentImport.shops.map((s) =>
                  s.id != id
                    ? s
                    : {
                        ...s,
                        ...this.state.newFormData,
                        custom_attributes: {
                          ...s.custom_attributes,
                          ...Object.keys(this.state.newFormData)
                            .filter((k) => k.startsWith('__custom'))
                            .reduce(
                              (a, b) => ({
                                ...a,
                                [b]: this.state.newFormData[b],
                              }),
                              {}
                            ),
                        },
                        image_src: !this.state.pendingFile
                          ? this.state.pendingFileRemove
                            ? ''
                            : s.image_src || ''
                          : `${process.env.REACT_APP_BOTTL_WEBROOT}/photos/finder/${s.id}-${this.state.pendingFile.name}`,
                      }
                ),
              ],
            },
          });

          this._showNotification(
            id === -1
              ? 'Vos attributs personnalisés ont bien été mis à jour'
              : 'Votre cave importée a bien été mise à jour.'
          );
        });
    };

    // Upload pending file if any
    if (this.state.pendingFile) {
      let fd = new FormData();
      fd.append('file', this.state.pendingFile);
      fd.append('id', user.id);
      fd.append('shopId', id);
      fd.append('token', user.token);
      fd.append('passphrase', user.passphrase);
      axios
        .post(
          `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/imported-shops/update-file`,
          fd,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((r) => {
          _performUpdate();
        });
    }
    // Remove existing file is asked
    else if (this.state.pendingFileRemove) {
      axios
        .post(
          `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/imported-shops/remove-file`,
          {
            id: user.id,
            token: user.token,
            passphrase: user.passphrase,
            shopId: id,
          }
        )
        .then((r) => {
          _performUpdate();
        });
    } else _performUpdate();
  }

  showUpdateForm(id) {
    this.setState({
      formShown: true,
      formData: this.state.currentImport.shops.find((s) => s.id == id),
      search: '',
    });
  }

  hideUpdateForm() {
    this.setState(
      {
        formShown: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            formData: { custom_attributes: {} },
            pendingFile: null,
            pendingFileRemove: false,
            pendingCustomAttributes: [],
            pendingCustomAttributesRenames: [],
            pendingCustomAttributesRemoves: [],
            editableCustomFields: [],
          });
        }, 250);
      }
    );
  }

  showCreateForm() {
    this.setState({
      formShown: true,
      formData: { custom_attributes: {} },
      pendingCustomAttributes: [],
      pendingCustomAttributesRenames: [],
      pendingCustomAttributesRemoves: [],
      editableCustomFields: [],
      search: '',
    });
  }

  handleFormChange(evt) {
    const { target } = evt;
    this.setState({
      newFormData: { ...this.state.newFormData, [target.name]: target.value },
    });
  }

  handleFilePick(evt) {
    evt.preventDefault();
    document
      .querySelector('.as-finder-file-group-input input[type="file"]')
      .click();
  }

  handleFileChange(evt) {
    const input = document.querySelector(
      '.as-finder-file-group-input input[type="file"]'
    );

    if (input.files.length === 0) return;

    const file = input.files[0];

    if (file.size / (1024 * 1024) > 5) {
      this._showError(
        'Veuillez utliser une image ne dépassant pas 5 mégaoctets.'
      );
      return;
    }

    if (!['jpg', 'png'].includes(file.name.split('.').at(-1))) {
      this._showError('Veuillez utliser une image au format jpg, ou png');
      return;
    }

    this.setState({
      formData: { ...this.state.formData, imported_image_filename: file.name },
      newFormData: {
        ...this.state.newFormData,
        imported_image_filename: file.name,
      },
      pendingFile: file,
    });
  }

  handleFileRemove(evt) {
    evt.preventDefault();

    this.setState({
      formData: { ...this.state.formData, imported_image_filename: null },
      newFormData: { ...this.state.newFormData, imported_image_filename: null },
      pendingFileRemove: true,
      pendingFile: false,
    });
  }

  handleSearch(evt) {
    this.setState({
      search: evt.target.value,
    });
  }

  deleteImport(evt) {
    evt.preventDefault();

    Swal.fire({
      title: 'Attention',
      text: `La suppression d'un import est irréversible, voulez-vous continuer ?`,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: `Annuler`,
    }).then((result) => {
      if (result.isDenied) return;
      if (!result.isConfirmed) return;

      this.setState({ loading: true, loaded: false });

      const {
        authentication: { user },
      } = this.props;
      let endpoint = `finder/imports/remove`;

      axios
        .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
          id: user.id,
          token: user.token,
          passphrase: user.passphrase,
          importId: this.state.currentImport.pk,
        })
        .then((res) => {
          this.setState({
            loaded: true,
            loading: false,
            imports: this.state.imports.filter(
              (i) => i.pk != this.state.currentImport.pk
            ),
            currentImport: null,
          });

          this._showNotification('Votre import a bien été supprimé.');
        })
        .catch((err) => {
          this.setState({
            loaded: true,
            loading: false,
          });
        });
    });
  }

  showFormGroup(key) {
    this.setState({
      currentFormGroup: key,
    });
  }

  appendNewCustomField() {
    const newAttribute = {
      pk: 'NEW_' + (this.state.pendingCustomAttributes.length + 1),
      fk_import: this.state.currentImport.pk,
      name: 'Champ personnalisé',
      type: 'text',
    };

    this.setState({
      pendingCustomAttributes: [
        ...this.state.pendingCustomAttributes,
        newAttribute,
      ],
    });
  }

  removeNewCustomField(pk) {
    this.setState({
      pendingCustomAttributes: this.state.pendingCustomAttributes.filter(
        (pca) => pca.pk !== pk
      ),
    });
  }

  removeCustomField(pk) {
    this.setState({
      pendingCustomAttributesRemoves: [
        ...this.state.pendingCustomAttributesRemoves,
        { pk },
      ],
    });
  }

  handlePendingCustomAttributeLabelChange(pk, label) {
    this.setState({
      pendingCustomAttributes: this.state.pendingCustomAttributes.map((a) =>
        a.pk === pk ? { ...a, newName: label } : a
      ),
    });
  }

  handleCustomAttributeRename(pk, label) {
    if (this.state.pendingCustomAttributesRenames.some((r) => r.pk === pk))
      this.setState({
        pendingCustomAttributesRenames: this.state.pendingCustomAttributesRenames.map(
          (a) => (a.pk === pk ? { ...a, newName: label } : a)
        ),
      });
    else
      this.setState({
        pendingCustomAttributesRenames: [
          ...this.state.pendingCustomAttributesRenames,
          { pk, newName: label },
        ],
      });
  }

  openCustomAttributesManager() {
    this.setState({
      showCustomAttributesManager: true,
    });
  }

  hideCustomAttributesManager() {
    this.setState({
      showCustomAttributesManager: false,
      pendingCustomAttributes: [],
      pendingCustomAttributesRenames: [],
      pendingCustomAttributesRemoves: [],
      editableCustomFields: [],
    });
  }

  enableCustomFieldRename(pk) {
    this.setState({
      editableCustomFields: [...this.state.editableCustomFields, { pk }],
    });

    setTimeout(() => {
      document.querySelector(`.nca-${pk}`).focus();
      document.querySelector(`.nca-${pk}`).click();
    }, 100);
  }

  render() {
    let {
      loaded,
      loading,
      subscription,
      imports,
      currentImport,
      formShown,
      formData,
      search,
      showDetail,
      currentFormGroup,
      pendingCustomAttributes,
      pendingCustomAttributesRenames,
      pendingCustomAttributesRemoves,
      showCustomAttributesManager,
      editableCustomFields,
    } = this.state;

    return (
      <Container className="dashboard">
        {!loaded && <Loading loaded={loaded} loading={loading} />}
        {/* FULL STATE */}
        {loaded &&
          subscription &&
          subscription.team.is_subscribed == true &&
          imports && (
            <React.Fragment>
              {/* 1ST ROW */}
              <Row>
                <Col>
                  <Row>
                    <Col md={!currentImport ? 8 : 4}>
                      <h3 className="page-title">
                        Mes bases
                        {!currentImport ? '' : ` > Import ${currentImport.pk}`}
                      </h3>
                      {!currentImport && imports.length > 0 && (
                        <p style={{ fontSize: '12.5pt' }}>
                          Importez vos bases dans Finder, et utilisez la
                          puissance de la plateforme pour explorer vos données.
                          <br />
                          <br />
                        </p>
                      )}
                    </Col>
                    <Col md={!currentImport ? 4 : 8} align="right">
                      {imports && !currentImport && (
                        <>
                          <Button
                            color="primary"
                            outline
                            size="sm"
                            onClick={() => {
                              this.props.history.push(
                                '/finder/intelligence/import'
                              );
                            }}
                          >
                            Faire un nouvel import
                          </Button>
                        </>
                      )}
                      {currentImport && (
                        <>
                          <Button
                            color="primary"
                            outline
                            size="sm"
                            onClick={() => {
                              this.props.history.push(
                                '/finder/intelligence/imports'
                              );
                            }}
                          >
                            Fermer
                          </Button>
                          <Button
                            color="primary"
                            outline
                            size="sm"
                            onClick={this.deleteImport}
                          >
                            Supprimer
                          </Button>
                          <Button
                            color="primary"
                            outline
                            size="sm"
                            onClick={(e) => {
                              this.showCreateForm();
                              e.stopPropagation();
                            }}
                          >
                            Ajouter une cave
                          </Button>
                          {subscription &&
                            ['Professional', 'Entreprise'].includes(
                              subscription.plan.name
                            ) && (
                              <Button
                                color="primary"
                                outline
                                size="sm"
                                onClick={this.openCustomAttributesManager}
                              >
                                Champs personnalisés
                              </Button>
                            )}
                          <Button
                            color="primary"
                            outline
                            size="sm"
                            href={`${process.env.REACT_APP_BOTTL_WEBROOT}/caves/rechercher/?import_id=${currentImport.pk}`}
                            target="_blank"
                          >
                            Voir dans Finder
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                  {/* NO IMPORT EVER MADE */}
                  {imports.length === 0 && (
                    <Row>
                      <Col md={12}>
                        <div className="subscription-placeholder">
                          <div className="placeholder-wrapper">
                            <p
                              style={{ textAlign: 'center' }}
                              className="placeholder"
                            >
                              Ici, vous pouvez charger votre ou vos bases de
                              données de cavistes et les visualiser dans Finder.
                              <br />
                              Cela vous permet de stocker vos données dans
                              Finder, de les mettre à jour quand vous voulez, de
                              les enrichir, et de bien en profiter.
                              <br />
                              Nous ne récupérons aucune donnée, ce sont
                              strictement les vôtres.
                            </p>
                            <br />
                            <button
                              onClick={() => {
                                this.props.history.push(
                                  '/finder/intelligence/import'
                                );
                              }}
                              className="btn btn-primary"
                            >
                              Nouvel import
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {/* LIST OF IMPORTS */}
                  {imports.length > 0 && !currentImport && (
                    <Row>
                      <Col md={12}>
                        <p className="as-import-intro">
                          Voici les derniers imports que vous avez effectués
                        </p>
                        <div className="as-imports-list">
                          {imports.map((i, idx) => (
                            <div
                              key={i.pk}
                              className="as-import"
                              onClick={(evt) => this.openImport(i)}
                            >
                              <div className="as-import-cell for-name">
                                {i.type === 'file' && (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                                      />
                                    </svg>
                                    #{i.pk} -
                                    {i.filename.length > 20
                                      ? i.filename.slice(0, 10) +
                                        '...' +
                                        i.filename.slice(-10)
                                      : i.filename}
                                  </>
                                )}
                                {i.type === 'copypaste' && (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                                      />
                                    </svg>
                                    #{i.pk} - Copier-coller
                                  </>
                                )}
                              </div>
                              <div className="as-import-cell for-stats">
                                {i.line_count} ligne
                                {i.line_count > 1 ? 's' : ''} /{' '}
                                {i.mapping_count} attribut
                                {i.mapping_count > 1 ? 's' : ''}
                              </div>
                              <div className="as-import-cell for-date">
                                Importé le{' '}
                                {moment(i.created_at).format('DD/MM/YYYY')}
                              </div>
                              <div className="as-import-cell for-button">
                                <a
                                  href={`/finder/intelligence/imports/${i.pk}`}
                                  className="btn btn-primary"
                                >
                                  Voir l'import
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  )}
                  {/* VIEW ONE IMPORT */}
                  {currentImport && (
                    <Row className="as-full-import">
                      <Col md={12}>
                        {/* CUSTOM ATTRIBUTES MANAGER */}
                        <div className="as-import-shops-overview"></div>

                        {/* LIST OF IMPORTED SHOPS */}
                        <div className="as-import-shops-overview">
                          <ImportedShopsMatTable
                            history={this.props.history}
                            data={currentImport.shops.filter(
                              (s) => s.belongs_to_import == true
                            )}
                            title="Liste des caves importées"
                            onShopOpen={this.shopOpen}
                            onShopDelete={this.shopDelete}
                            onShowUpdateForm={this.showUpdateForm}
                          />
                        </div>

                        {/* LIST OF ADDED SHOPS */}
                        <div className="as-import-shops-overview">
                          <AddedShopsMatTable
                            history={this.props.history}
                            data={currentImport.shops.filter(
                              (s) => s.belongs_to_import == false
                            )}
                            title="Liste des caves ajoutées"
                            onShopOpen={this.shopOpen}
                            onShowCreateForm={this.showCreateForm}
                            onShowUpdateForm={this.showUpdateForm}
                            onShopDelete={this.shopDelete}
                          />
                        </div>

                        {/* FULL DETAILS */}
                        {!showDetail && (
                          <Row>
                            <Col md={12} align={'center'}>
                              <Button
                                color="primary"
                                outline
                                onClick={() => {
                                  this.setState({ showDetail: true });
                                }}
                              >
                                Voir le détail de l'import
                              </Button>
                            </Col>
                          </Row>
                        )}
                        {showDetail && (
                          <div className="as-import-full-details">
                            <div className="as-flex as-space-between as-align-center">
                              <p className="as-import-intro">
                                Voici le détail de votre import
                              </p>
                              <Button
                                color="primary"
                                outline
                                onClick={() => {
                                  this.setState({ showDetail: false });
                                }}
                              >
                                Cacher le détail de l'import
                              </Button>
                            </div>
                            <Row>
                              <Col md={6}>
                                {/* SETTINGS */}
                                <div className="as-import-settings">
                                  <p>Paramètres de votre import</p>

                                  <div className="wrapper">
                                    <div className="as-import-setting">
                                      <span>Méthode d'import</span>
                                      <span>
                                        {currentImport.type === 'file'
                                          ? 'Fichier'
                                          : 'Copier-coller'}
                                      </span>
                                    </div>
                                    {currentImport.type === 'file' && (
                                      <div className="as-import-setting">
                                        <span>Nom du fichier</span>
                                        <span>{currentImport.filename}</span>
                                      </div>
                                    )}
                                    <div className="as-import-setting">
                                      <span>Entêtes inclus</span>
                                      <span>
                                        {currentImport.has_header
                                          ? 'Oui'
                                          : 'Non'}
                                      </span>
                                    </div>
                                    <div className="as-import-setting">
                                      <span>Délimiteur</span>
                                      <span>
                                        {{
                                          ',': 'Virgule',
                                          ';': 'Point-virgule',
                                          '\t': 'Tabulation',
                                        }[currentImport.delimiter] || 'Aucun'}
                                      </span>
                                    </div>
                                    <div className="as-import-setting">
                                      <span>Nombre de lignes</span>
                                      <span>{currentImport.line_count}</span>
                                    </div>
                                    <div className="as-import-setting">
                                      <span>Nombre d'attributs</span>
                                      <span>
                                        {currentImport.mappings.length}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col md={6}>
                                {/* MAPPINGS */}
                                <div className="as-import-mappings-settings">
                                  <p>Mappage de votre import</p>

                                  <div className="wrapper">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Colonne d'import</th>
                                          <th>Champ de cave</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {currentImport.mappings.map(
                                          (m, idx) => (
                                            <tr
                                              key={idx}
                                              className="as-import-mappings-setting"
                                            >
                                              <td>
                                                {/__col\d__/.test(
                                                  m.import_column
                                                )
                                                  ? `Champ ${
                                                      m.import_column.split(
                                                        'col'
                                                      )[1][0]
                                                    }`
                                                  : m.import_column}
                                              </td>
                                              <td>
                                                {knownCaveFields[m.shop_column]}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {/* OVERVIEW */}
                            <Overview _import={currentImport} isFull={true} />
                          </div>
                        )}
                      </Col>
                      {/* CREATE / UPDATE FORM */}
                      <div
                        className={`as-imported-shop-form-popup ${
                          formShown ? 'active' : ''
                        }`}
                      >
                        <div
                          onClick={this.hideUpdateForm}
                          className="as-overlay"
                        ></div>
                        <div className="as-content">
                          <span>
                            {formData['id'] ? 'Mise à jour' : 'Ajout'}
                          </span>
                          <p>
                            {formData['id']
                              ? 'Ce formulaire vous permet de mettre à jour votre cave importée.'
                              : "Ce formulaire vous permet d'ajouter une cave à votre import."}
                          </p>
                          <input
                            type="text"
                            placeholder="Rechercher..."
                            className="as-finder-field-searcher"
                            value={search}
                            onChange={this.handleSearch}
                          />
                          <form>
                            <div className="as-import-fields-picker">
                              <button
                                type="button"
                                className={
                                  currentFormGroup === 'native' ? 'active' : ''
                                }
                                onClick={() => {
                                  this.showFormGroup('native');
                                }}
                              >
                                Attributs natifs
                              </button>
                              {subscription &&
                                ['Professional', 'Entreprise'].includes(
                                  subscription.plan.name
                                ) && (
                                  <button
                                    type="button"
                                    className={
                                      currentFormGroup === 'custom'
                                        ? 'active'
                                        : ''
                                    }
                                    onClick={() => {
                                      this.showFormGroup('custom');
                                    }}
                                  >
                                    Champs personnalisés
                                  </button>
                                )}
                            </div>
                            <div
                              className="as-import-fields-group"
                              style={{
                                display:
                                  currentFormGroup === 'native'
                                    ? 'inherit'
                                    : 'none',
                              }}
                            >
                              {Object.entries(knownCaveFields).map((e) => (
                                <div
                                  key={e[0]}
                                  style={{
                                    display: `${
                                      e[1]
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                        ? 'flex'
                                        : 'none'
                                    }`,
                                  }}
                                >
                                  <label>{e[1]}</label>
                                  {e[0] !== 'imported_image_filename' ? (
                                    <input
                                      name={e[0]}
                                      type="text"
                                      placeholder="Renseigner une valeur"
                                      defaultValue={formData[e[0]] || ''}
                                      onInput={this.handleFormChange}
                                    />
                                  ) : (
                                    <div className="as-finder-file-group-input">
                                      <input
                                        type="text"
                                        placeholder="Choisir un fichier"
                                        value={
                                          formData['imported_image_filename'] ||
                                          ''
                                        }
                                        disabled={true}
                                      />
                                      <input
                                        style={{ display: 'none' }}
                                        type="file"
                                        onChange={this.handleFileChange}
                                        accept=".png,.jpg"
                                      />
                                      <button
                                        type="button"
                                        onClick={this.handleFilePick}
                                      >
                                        Parcourir
                                      </button>
                                      {formData['imported_image_filename'] && (
                                        <button
                                          type="button"
                                          onClick={this.handleFileRemove}
                                        >
                                          Effacer
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                            <div
                              className="as-import-fields-group"
                              style={{
                                display:
                                  currentFormGroup === 'custom'
                                    ? 'inherit'
                                    : 'none',
                              }}
                            >
                              {currentImport.custom_attributes && (
                                <>
                                  {currentImport.custom_attributes.map((e) => (
                                    <div
                                      key={e.pk}
                                      style={{
                                        display: `${
                                          e.name
                                            .toLowerCase()
                                            .includes(search.toLowerCase())
                                            ? 'flex'
                                            : 'none'
                                        }`,
                                      }}
                                    >
                                      <label
                                        // contentEditable={true}
                                        contentEditable={false}
                                        onInput={(evt) => {
                                          this.handleCustomAttributeRename(
                                            e.pk,
                                            evt.currentTarget.textContent
                                          );
                                        }}
                                        suppressContentEditableWarning={true}
                                      >
                                        {e.name}
                                      </label>
                                      <input
                                        name={`__custom${e.pk}__`}
                                        type="text"
                                        placeholder="Renseigner une valeur"
                                        defaultValue={
                                          formData['custom_attributes'][
                                            `__custom${e.pk}__`
                                          ] || ''
                                        }
                                        onInput={this.handleFormChange}
                                      />
                                    </div>
                                  ))}
                                  {pendingCustomAttributes.map((e) => (
                                    <div
                                      key={e.pk}
                                      style={{
                                        display: `${
                                          e.name
                                            .toLowerCase()
                                            .includes(search.toLowerCase())
                                            ? 'flex'
                                            : 'none'
                                        }`,
                                      }}
                                    >
                                      <label
                                        // contentEditable={true}
                                        contentEditable={false}
                                        onInput={(evt) => {
                                          this.handlePendingCustomAttributeLabelChange(
                                            e.pk,
                                            evt.currentTarget.textContent
                                          );
                                        }}
                                        suppressContentEditableWarning={true}
                                      >
                                        {e.name}
                                      </label>
                                      <input
                                        name={`__custom${e.pk}__`}
                                        type="text"
                                        placeholder="Renseigner une valeur"
                                        defaultValue={
                                          formData['custom_attributes'][
                                            `__custom${e.pk}__`
                                          ] || ''
                                        }
                                        onInput={this.handleFormChange}
                                      />
                                    </div>
                                  ))}
                                  {/* ADD NEW CUSTOM FIELD */}
                                  {pendingCustomAttributes.length === 0 &&
                                    Object.keys(currentImport.custom_attributes)
                                      .length === 0 && (
                                      <p>
                                        Vous n'avez défini aucun champ
                                        personnalisé pour le moment.
                                      </p>
                                    )}

                                  {/* DESACTIVE POUR LE MOMENT */}
                                  {false && (
                                    <button
                                      type="button"
                                      className="as-add-custom-field-btn"
                                      onClick={this.appendNewCustomField}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-6 h-6"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </button>
                                  )}
                                </>
                              )}
                            </div>
                          </form>
                          <div className="as-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                this.hideUpdateForm();
                              }}
                            >
                              Annuler
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={() => {
                                formData.id
                                  ? this.shopUpdate(formData.id)
                                  : this.shopCreate();
                              }}
                            >
                              Enregistrer
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* CUSTOM ATTRIBUTES MANAGER */}
                      <div
                        className={`as-imported-shop-form-popup for-custom-attributes ${
                          showCustomAttributesManager ? 'active' : ''
                        }`}
                      >
                        <div
                          onClick={this.hideCustomAttributesManager}
                          className="as-overlay"
                        ></div>
                        <div className="as-content">
                          <span>Champs personnalisés</span>
                          <p>
                            Ce formulaire vous permet de gérer les champs
                            personnalisés de votre import.
                          </p>
                          <form>
                            <div className="as-import-fields-group for-ca">
                              {currentImport.custom_attributes && (
                                <>
                                  {currentImport.custom_attributes
                                    .filter(
                                      (e) =>
                                        !pendingCustomAttributesRemoves.some(
                                          (_e) => _e.pk === e.pk
                                        )
                                    )
                                    .map((e) => (
                                      <div key={e.pk}>
                                        <label
                                          // contentEditable={true}
                                          contentEditable={editableCustomFields.some(
                                            (_e) =>
                                              _e.pk === e.pk ? true : false
                                          )}
                                          onInput={(evt) => {
                                            this.handleCustomAttributeRename(
                                              e.pk,
                                              evt.currentTarget.textContent
                                            );
                                          }}
                                          suppressContentEditableWarning={true}
                                          className={`nca-${e.pk}`}
                                        >
                                          {e.name}
                                        </label>
                                        <Button
                                          color="primary"
                                          outline
                                          size="sm"
                                          style={{
                                            visibility: !editableCustomFields.some(
                                              (_e) => _e.pk === e.pk
                                            )
                                              ? 'visible'
                                              : 'hidden',
                                          }}
                                          onClick={() => {
                                            this.enableCustomFieldRename(e.pk);
                                          }}
                                        >
                                          Renommer
                                        </Button>
                                        <Button
                                          color="danger"
                                          outline
                                          size="sm"
                                          onClick={() => {
                                            this.removeCustomField(e.pk);
                                          }}
                                        >
                                          Supprimer
                                        </Button>
                                      </div>
                                    ))}
                                  {pendingCustomAttributes.map((e) => (
                                    <div key={e.pk}>
                                      <label
                                        // contentEditable={true}
                                        contentEditable={editableCustomFields.some(
                                          (_e) =>
                                            _e.pk === e.pk ? true : false
                                        )}
                                        onInput={(evt) => {
                                          this.handlePendingCustomAttributeLabelChange(
                                            e.pk,
                                            evt.currentTarget.textContent
                                          );
                                        }}
                                        suppressContentEditableWarning={true}
                                        className={`nca-${e.pk}`}
                                      >
                                        {e.name}
                                      </label>
                                      <Button
                                        color="primary"
                                        outline
                                        size="sm"
                                        onClick={() => {
                                          this.enableCustomFieldRename(e.pk);
                                        }}
                                        style={{
                                          visibility: !editableCustomFields.some(
                                            (_e) => _e.pk === e.pk
                                          )
                                            ? 'visible'
                                            : 'hidden',
                                        }}
                                      >
                                        Renommer
                                      </Button>
                                      <Button
                                        color="danger"
                                        outline
                                        size="sm"
                                        onClick={() => {
                                          this.removeNewCustomField(e.pk);
                                        }}
                                      >
                                        Supprimer
                                      </Button>
                                    </div>
                                  ))}
                                  {/* ADD NEW CUSTOM FIELD */}
                                  {pendingCustomAttributes.length === 0 &&
                                    Object.keys(currentImport.custom_attributes)
                                      .length === 0 && (
                                      <p>
                                        Vous n'avez défini aucun champ
                                        personnalisé pour le moment.
                                      </p>
                                    )}
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{
                                      maxWidth: 360,
                                      marginTop: 24,
                                      marginLeft: 'auto',
                                      marginRight: 'auto',
                                    }}
                                    onClick={this.appendNewCustomField}
                                  >
                                    Ajouter un nouveau champ personnalisé
                                  </button>
                                </>
                              )}
                            </div>
                          </form>
                          <div className="as-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                this.hideCustomAttributesManager();
                              }}
                            >
                              Annuler
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={() => {
                                this.shopUpdate(-1);
                              }}
                            >
                              Enregistrer
                            </button>
                          </div>
                        </div>
                      </div>
                    </Row>
                  )}
                </Col>
              </Row>
            </React.Fragment>
          )}
        {/* EMPTY VOID UNSUBSCRIBED STATE */}
        {loaded && (!subscription || subscription.team.is_subscribed == false) && (
          <div className="subscription-placeholder">
            <div className="placeholder-wrapper">
              <p className="placeholder">
                Vous n'avez aucun abonnement à Finder pour l'instant.
              </p>
              <br />
              <a
                href={`${process.env.REACT_APP_BOTTL_WEBROOT}/finder`}
                className="btn btn-primary"
              >
                Je m'abonne
              </a>
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Imports);
